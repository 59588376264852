<template>
  <div class="contentBqd">
    <div class="details-top">账单详情</div>
    <div class="details-content-box">
      <div class="main-detail">
        <div v-if="searchObj.zdlx=='1'">
          <div class="main">
            <div
              class="list-item"
              v-for="(item,index) in zdTemplate.dataProp"
              :key="index"
            >
              <div class="left_box">
                {{item.label}}
              </div>
              <div class="right_box">{{zdData[item.prop]}}</div>
            </div>
          </div>
          <van-divider :style="{padding: '10px 16px',fontSize:'12px'}">第三方结算</van-divider>
          <div class="other">
            <div
              class="list-item"
              v-for="(item,index) in zdTemplate.otherProp"
              :key="index"
            >
              <div class="left_box">
                {{item.label}}
              </div>
              <div class="right_box">{{zdData[item.prop]}}</div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="main"
        >
          <div
            class="list-item"
            v-for="(item,index) in zdTemplate.dataProp"
            :key="index"
          >
            <div class="left_box">
              {{item.label}}
            </div>
            <div class="right_box">{{zdData[item.prop]}}</div>
          </div>
        </div>
      </div>
      <div style="padding:20px 0">
        <van-button
          v-if="zdData.sfxz==0"
          round
          type="info"
          @click="$router.replace(`/payFee?zdData=${JSON.stringify(currZdData)}&type=single&searchObj=${JSON.stringify(searchObj)}`)"
        >立即支付</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBillDetailsApi
} from '@/reuqest/api.js'
export default {
  props: {
    searchObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currZdData: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      zdData: {},
      zdStyle: 1,
      zdTemplate: {
        dataProp: [],
        otherProp: []
      },//账单模板
    }
  },
  computed: {
  },
  watch: {
    'currZdData.id': {
      handler (val) {
        this.initTemplate()
        this.init()
      },
      deep: true,
    },
  },
  methods: {
    init () {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      getBillDetailsApi({
        id: this.currZdData.id,
        htlx: this.searchObj.htlx,
        zdlx: this.searchObj.zdlx
      }).then((res) => {
        this.zdData = res.data
        loadingToast.clear()
      })
    },
    initTemplate () {
      let dataProp = {}
      let otherProp = {}
      if (this.searchObj.htlx == 'gzh_wbht' && this.searchObj.zdlx == '1') {//维保-收款票据
        dataProp = {
          htbh: '合同编号',
          xmmc: '项目名称',
          khmc: '客户名称',
          wbdw: '维保单位',
          fylx: '费用类型',
          skzq: '收款周期',
          kprq: '开票日期',
          kpje: '开票金额'
        }
        otherProp = {
          jsbl: '结算比例%',
          yjsje: '应结算金额',
          jszt: '结算状态'
        }
      } else if (this.searchObj.htlx == 'gzh_wbht' && this.searchObj.zdlx == '2') {//整改报价
        dataProp = {
          bjbh: '报价编号',
          xmmc: '项目名称',
          khmc: '客户名称',
          wbdw: '维保单位',
          bjrq: '报价日期',
          sl: '税率%',
          hs: '含税',
          tysl: '统一税率',
          qrje: '金额'
        }
      }  else if (this.searchObj.htlx == 'gzh_wbht' && this.searchObj.zdlx == '4') {//保证金
        dataProp = {
          xmmc: '项目名称',
          khmc: '客户名称',
          bzjlx: '保证金类型',
          zfje: '支付金额'
        }
      } else if (this.searchObj.htlx == 'gzh_wbht' && this.searchObj.zdlx == '5') {//报价业务
        dataProp = {
          fylx: '票据编号',
          fylx: '费用类型',
          fyms: '费用描述',
          jhzfrq: '计划支付日期',
          zdje: '账单金额',
        }
      } else if (this.searchObj.htlx == 'gzh_wbht' && this.searchObj.zdlx == '6') {//年检费用
        dataProp = {
          jfdbh: '缴费单编号',
          jflx: '缴费类型',
          jydw: '检验单位',
          jyrq: '检验日期',
          sydw: '使用单位',
          jfje: '缴费金额',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
      } else if (this.searchObj.htlx == 'gzh_xsht' && this.searchObj.zdlx == '1') {//收款计划
        dataProp = {
          htbh: '合同编号',
          xmmc: '项目名称',
          fyxm: '费用项目',
          fylx: '费用类型',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
        otherProp = {
          jsbl: '结算比例%',
          yjsje: '应结算金额',
          jszt: '结算状态'
        }
      } else if (this.searchObj.htlx == 'gzh_xsht' && this.searchObj.zdlx == '2') {//支付款项
        dataProp = {
          fyxm: '费用项目',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
      } else if (this.searchObj.htlx == 'gzh_xsht' && this.searchObj.zdlx == '4') {//非采购支出
        dataProp = {
          pjbh: '票据编号',
          fylx: '费用类型',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
      } else if (this.searchObj.htlx == 'gzh_xsht' && this.searchObj.zdlx == '5') {//保证金
        dataProp = {
          bzjlx: '保证金类型',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
      } else if (this.searchObj.htlx == 'gzh_azht' && this.searchObj.zdlx == '1') {//工程票据
        dataProp = {
          htbh: '合同编号',
          xmmc: '项目名称',
          pjbh: '票据编号',
          khmc: '客户名称',
          kxlx: '款项类型',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
        otherProp = {
          jsbl: '结算比例%',
          yjsje: '应结算金额',
          jszt: '结算状态'
        }
      } else if (this.searchObj.htlx == 'gzh_azht' && this.searchObj.zdlx == '2') {//工程报价
        dataProp = {
          bjbh: '报价编号',
          xmmc: '项目名称',
          khmc: '客户名称',
          azdw: '安装单位',
          zdrq: '报价日期',
          sl: '税率%',
          hs: '含税',
          tysl: '统一税率',
          zdje: '金额'
        }
      }  else if (this.searchObj.htlx == 'gzh_azht' && this.searchObj.zdlx == '4') {//保证金
        dataProp = {
          xmmc: '项目名称',
          khmc: '客户名称',
          pjbh: '票据编号',
          bzjlx: '保证金类型',
          zdrq: '账单日期',
          zdje: '账单金额'
        }
      } else if (this.searchObj.htlx == 'gzh_azht' && this.searchObj.zdlx == '5') {//其他支出
        dataProp = {
          xmmc: '项目名称',
          pjbh: '票据编号',
          fylx: '费用类型',
          zdrq: '账单日期',
          zdje: '账单金额'
        }
      } else if (this.searchObj.htlx == 'gzh_xiusht' && this.searchObj.zdlx == '1') {//收款计划
        dataProp = {
          htbh: '合同编号',
          xmmc: '项目名称',
          fyxm: '费用项目',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
        otherProp = {
          jsbl: '结算比例%',
          yjsje: '应结算金额',
          jszt: '结算状态'
        }
      } else if (this.searchObj.htlx == 'gzh_xiusht' && this.searchObj.zdlx == '2') {//报价单
        dataProp = {
          bjbh: '报价编号',
          xmmc: '项目名称',
          khmc: '客户名称',
          qygs: '签约公司',
          zdrq: '报价日期',
          sl: '税率%',
          hs: '含税',
          tysl: '统一税率',
          zdje: '金额'
        }
      } else if (this.searchObj.htlx == 'gzh_xiusht' && this.searchObj.zdlx == '4') {//保证金
        dataProp = {
          xmmc: '项目名称',
          khmc: '客户名称',
          pjbh: '票据编号',
          bzjlx: '保证金类型',
          zdrq: '账单日期',
          zdje: '账单金额'
        }
      } else if (this.searchObj.htlx == 'gzh_xiusht' && this.searchObj.zdlx == '5') {//其他支出
        dataProp = {
          xmmc: '项目名称',
          pjbh: '票据编号',
          fylx: '费用类型',
          zdrq: '账单日期',
          zdje: '账单金额'
        }
      } else if (this.searchObj.htlx == 'gzh_xiusht' && this.searchObj.zdlx == '6') {//支付款项
        dataProp = {
          fyxm: '费用项目',
          zdje: '账单金额',
          zdrq: '账单日期'
        }
      }
      let mainList = []
      for (let key in dataProp) {
        mainList.push({
          label: dataProp[key],
          prop: key,
        })
      }
      let otherList = []
      for (let key in otherProp) {
        otherList.push({
          label: otherProp[key],
          prop: key,
        })
      }
      this.zdTemplate.dataProp = mainList
      this.zdTemplate.otherProp = otherList
    },
  },
  created () {
  },
}
</script>
 
<style lang="less" scoped>
.details-top {
  font-size: 16px;
  background-color: #fff;
  height: 47px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details-content-box {
  height: calc(100vh - 47px);
  overflow-y: auto;
  .main-detail {
    padding: 0 12px;
    .list-item {
      display: grid;
      grid-template-columns: 25% 75%;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      font-size: 14px;
      border-bottom: 1px solid #f1f1f1;
      text-align: left;
      .left_box {
        font-weight: 500;
      }
      .right_box {
        padding-left: 10px;
      }
    }
    .main {
      .list-item:first-child {
        border-top: none !important;
      }
      .list-item:last-child {
        border-bottom: none !important;
      }
    }
    .other {
      .list-item:first-child {
        border-top: none !important;
      }
      .list-item:last-child {
        border-bottom: none !important;
      }
    }
  }
}
.contentBqd {
  height: 100vh;
  background-color: white;
}
* {
  margin: 0;
  padding: 0;
}
.van-button__content {
  width: 65vw !important;
}
</style>